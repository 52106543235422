import { Typography, type TypographyProps } from '@mui/material';

const ImageTextItem = ({
  content,
  numberOfLines = 1,
  props
}: {
  content: string;
  numberOfLines?: number;
  props?: TypographyProps;
}) => (
  <Typography
    {...props}
    sx={{
      fontSize: 10,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: numberOfLines,
      WebkitBoxOrient: 'vertical'
    }}>
    {content}
  </Typography>
);

export default ImageTextItem;
