import { Box, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const ShortDescriptionItems = [
  { title: 'SỐ ĐO', value: 'NGANG 56 - DÀI 76' },
  { title: 'VẢI', value: 'LINEN' },
  { title: 'MÀU', value: 'XANH LÁ' }
];

const slideIn = keyframes`
  0% {
    transform: translateY(80px);
  }
  40% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const DescriptionContainer = styled.div`
  animation: ${slideIn} 0.6s ease-in-out;
  display: block !important;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80px;
  background-color: white;
  width: 60%;
`;

const ShortDescription = () => (
  <DescriptionContainer>
    {ShortDescriptionItems.map(({ title, value }, index) => (
      <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>
        <Typography variant="caption" sx={{ flex: 1 }}>
          {title}
        </Typography>
        <Typography variant="caption" sx={{ flex: 2 }}>
          {value}
        </Typography>
      </Box>
    ))}
  </DescriptionContainer>
);

export default ShortDescription;
