import { Route, Routes } from 'react-router-dom';

import CartPage from '../pages/CartPage';
import DiscoveryPage from '../pages/DiscoveryPage';
import EditorialsPage from '../pages/EditorialsPage';
import LandingPage from '../pages/LandingPage';
import ProductDetailPage from '../pages/ProductDetailPage';
import ProductsPage from '../pages/ProductsPage';

const RoutesChild = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/products" element={<ProductsPage />} />
    <Route path="/cart" element={<CartPage />} />
    <Route path="/editorials" element={<EditorialsPage />} />
    <Route path="/product-detail/:productId" element={<ProductDetailPage />} />
    <Route path="/discovery" element={<DiscoveryPage />} />
  </Routes>
);

export default RoutesChild;
