import { useDispatch } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { type CartItem, deleteItemCart, updateQuantityCart } from '../../redux/features/CartSlice';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Divider = styled.hr`
  border-top: 1px solid #bbb;
  margin: 0px;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: grey;
  cursor: pointer;
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
  }
`;

const CartItemEditable = ({ product: { image, name, price, id }, qty }: CartItem) => {
  const dispatch = useDispatch();

  const handleIncreaseQuantity = () => {
    dispatch(updateQuantityCart({ id, type: 'increase' }));
  };

  const handleDecreaseQuantity = () => {
    dispatch(updateQuantityCart({ id, type: 'decrease' }));
  };

  const handleDeleteCartItem = () => {
    dispatch(deleteItemCart({ id }));
  };

  return (
    <div style={{ border: '1px solid grey', borderLeft: 'none' }}>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <Image src={image} />
      </div>
      <Divider />
      <Stack p={0.5} height={'100%'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="body2" sx={{ flex: 2 }}>
            {name}
          </Typography>
          <ClearButton onClick={handleDeleteCartItem}>
            <ClearIcon fontSize="small" />
          </ClearButton>
        </Box>
        <Stack pb={1}>
          <Typography variant="caption">SIZE L | COLOR RED</Typography>
          <Typography fontFamily="monospace" variant="caption">
            {price}
          </Typography>
        </Stack>
        <Box display={'flex'} border={`1px solid grey`} width={'fit-content'}>
          <QuantityButton onClick={handleDecreaseQuantity} disabled={qty === 0}>
            -
          </QuantityButton>
          <Typography fontFamily="monospace" variant="caption" lineHeight={'30px'} px={0.5}>
            {qty}
          </Typography>
          <QuantityButton onClick={handleIncreaseQuantity}>+</QuantityButton>
        </Box>
      </Stack>
    </div>
  );
};

export default CartItemEditable;
