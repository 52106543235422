import CartPageContent from '../../layouts/CartPageContent';
import PageLayout from '../../layouts/PageLayout';

const CartPage = () => (
  <PageLayout>
    <CartPageContent />
  </PageLayout>
);

export default CartPage;
