import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const SearchBar = () => (
  <Box
    sx={{
      bottom: 0,
      width: '75%',
      display: 'flex',
      position: 'fixed'
    }}>
    <Box
      sx={{
        color: 'white',
        backgroundColor: 'black',
        width: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Typography>SẢN PHẨM</Typography>
    </Box>
    <Box
      sx={{
        pl: 2,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'grey 1px solid'
      }}>
      <TextField fullWidth variant="standard" inputProps={{ style: { height: '25px' } }} />
    </Box>
  </Box>
);

export default SearchBar;
