import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { type CartItem } from '../../redux/features/CartSlice';

const Image = styled.img`
  width: 35%;
  height: auto;
`;

const CartMiniItem = ({ product: { id, image, name, price }, qty }: CartItem) => (
  <Box display={'flex'} border={`1px solid #333333`} borderRadius={'4px'}>
    <Image src={image} />

    <Box borderLeft={`1px solid #333333`} p={1}>
      <Typography variant="body2">{name}</Typography>
      <Stack py={0.5}>
        <Typography variant="body2">SIZE L / MÀU ĐỎ</Typography>
        <Typography fontFamily="monospace" variant="body2">
          {price}
        </Typography>
      </Stack>
    </Box>
  </Box>
);

export default CartMiniItem;
