import React from 'react';
import { Box, Typography } from '@mui/material';

const Filters = () => (
  <Box sx={{ flex: 3, pl: 1 }}>
    <Box>
      <Typography fontWeight={500}>SẮP XẾP THEO:</Typography>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="body2">CÒN HÀNG</Typography>
      <Typography variant="body2">HẾT HÀNG</Typography>
      <Typography variant="body2">GIÁ TỪ THẤP ĐẾN CAO</Typography>
      <Typography variant="body2">GIÁ TỪ CAO ĐẾN THẤP</Typography>
    </Box>
  </Box>
);

export default Filters;
