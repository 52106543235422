import React from 'react';
import { Grid } from '@mui/material';
import { styled } from 'styled-components';

import Signature3 from '../../assets/images/article_1.jpg';
import Signature2 from '../../assets/images/article_2.png';
import Signature1 from '../../assets/images/sig_1.jpg';
import Signature4 from '../../assets/images/sig_3.jpg';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const SignaturesList = [
  { image: Signature1 },
  { image: Signature2 },
  { image: Signature3 },
  { image: Signature4 }
];

const Signatures = () => (
  <Grid
    container
    rowSpacing={0}
    columnSpacing={0}
    rowGap={0}
    columnGap={0}
    spacing={0}
    columns={24}
    gap={0}>
    {SignaturesList.map(({ image }, index) => (
      <Grid xs={12} md={12} key={index} sx={{ margin: 0, padding: 0 }}>
        <Image src={image} alt="signature-pic" />
      </Grid>
    ))}
  </Grid>
);

export default Signatures;
