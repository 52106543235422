import React from 'react';

import LandingPageContent from '../../layouts/LandingPageContent';
import PageLayout from '../../layouts/PageLayout';

const LandingPage = () => (
  <PageLayout>
    <LandingPageContent />
  </PageLayout>
);

export default LandingPage;
