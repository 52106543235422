import { Box, Divider, ImageList, useMediaQuery } from '@mui/material';

import Article4 from '../../assets/images/article_1.jpg';
import Article2 from '../../assets/images/article_2.png';
import Article3 from '../../assets/images/article_3.png';
import MainPic from '../../assets/images/main_pic.jpg';
import ArticleItem from '../../components/ArticleItem';
import SearchBar from '../../shared/components/SearchBar';

const itemData = [
  {
    img: MainPic,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article2,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article3,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article4,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do.'
  }
];

const EditorialsPageContent = () => {
  const matches = useMediaQuery('(min-width:1400px)');

  return (
    <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
      <ImageList cols={matches ? 2 : 1} variant="masonry" gap={0} sx={{ margin: 0, padding: 0 }}>
        {itemData.map(({ description, img, title }, index) => (
          <ArticleItem key={index} img={img} title={title} description={description} />
        ))}
      </ImageList>
      <Divider />
      <SearchBar />
    </Box>
  );
};

export default EditorialsPageContent;
