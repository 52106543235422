import DiscoveryPageContent from '../../layouts/DiscoveryPageContent';
import PageLayout from '../../layouts/PageLayout';

const DiscoveryPage = () => (
  <PageLayout>
    <DiscoveryPageContent />
  </PageLayout>
);

export default DiscoveryPage;
