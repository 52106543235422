import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { styled } from 'styled-components';

import { addToCart, type Product } from '../../redux/features/CartSlice';

import ShortDescription from './ShortDescription';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Divider = styled.hr`
  border-top: 1px solid #bbb;
  margin: 0px;
`;

const AddToCartSection = styled.div`
  position: absolute;
  top: 0;
  background-color: black;
  padding: 0 5%;
`;

const Hover = styled(Typography)`
  cursor: pointer;
  padding-left: 10px;
`;

interface ProductItemProps extends Product {}

const ProductItem = ({ price, name, image, id }: ProductItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [description, setDescription] = useState<boolean>(false);

  const handleAddToCart = () => {
    dispatch(addToCart({ product: { image, price, name, id }, qty: 1 }));
  };

  return (
    <div style={{ borderRight: '1px solid grey' }}>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <AddToCartSection>
          <Button variant="text" style={{ color: 'white' }} onClick={handleAddToCart}>
            THÊM VÀO GIỎ
          </Button>
        </AddToCartSection>

        <Image src={image} />
        {description ? <ShortDescription /> : null}
      </div>
      <Divider />
      <Box>
        <Hover
          variant="caption"
          zIndex={2}
          onMouseLeave={() => {
            setDescription(false);
          }}
          onMouseEnter={() => {
            setDescription(true);
          }}>
          MÔ TẢ NGẮN ▲
        </Hover>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'right' }} ml={1}>
          <Box
            component={'div'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(`/product-detail/${id}`);
            }}>
            <Typography variant="body2" sx={{ flex: 2 }}>
              {name}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', textAlign: 'right', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ flex: 1 }}>
              GIÁ
            </Typography>
            <Typography fontFamily="monospace" fontSize={14} ml={1} mr={1}>
              {price}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ProductItem;
