import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { totalItemQtySelector } from '../../redux/features/CartSlice';
import { useAppSelector } from '../../redux/store';
import ButtonLink from '../../shared/components/ButtonLink';

const InfoText = ({ content }: { content: string }) => (
  <Typography sx={{ fontSize: 10 }} fontWeight={600}>
    {content}
  </Typography>
);

const AdditionalInfos = [
  {
    link: './',
    content: 'GIỚI THIỆU'
  },
  {
    link: './',
    content: 'HỖ TRỢ'
  },
  {
    link: './',
    content: 'HƯỚNG DẪN MUA HÀNG'
  },
  {
    link: './',
    content: 'GIAO HÀNG & THANH TOÁN'
  }
];

const ContactInfo = () => {
  const totalCartItems = useAppSelector(totalItemQtySelector);

  return (
    <Grid
      container
      columns={24}
      sx={{
        '--Grid-borderWidth': '1px',
        borderTop: 'var(--Grid-borderWidth) solid',
        borderColor: 'grey',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'grey'
        }
      }}>
      <Grid xs={24} sx={{ display: 'flex', alignItems: 'center' }} pl={2} pt={0.3} pb={0.3}>
        <ButtonLink
          link="./cart"
          content={`GIỎ HÀNG (${totalCartItems})`}
          props={{ fontSize: 10, fontWeight: '600' }}
        />
      </Grid>

      <Grid xs={10} pl={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
          {AdditionalInfos.map(({ link, content }, index) => (
            <ButtonLink
              key={index}
              link={link}
              content={content}
              props={{ fontSize: 10, fontWeight: '600' }}
            />
          ))}
        </Box>
      </Grid>
      <Grid xs={14}>
        <Box
          mt={0.6}
          mb={2}
          ml={2}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <Typography sx={{ fontSize: 10 }} fontWeight={600}>
            VẠC&apos;s CLOSET
          </Typography>
          <InfoText content="497/3 SƯ VẠN HẠNH,P12,Q10,HCM" />
          <InfoText content="0909 92 52 91" />
          <InfoText content="0901 97 42 91" />
          <InfoText content="9:00AM - 10:00PM" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactInfo;
