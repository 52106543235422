import { styled } from 'styled-components';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import CarouselImage from '../../assets/images/article_1.jpg';

import 'swiper/css';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Carousel = () => (
  <Swiper
    centeredSlides={false}
    slidesPerView={6}
    spaceBetween={0}
    speed={1200}
    autoplay={{
      delay: 500,
      disableOnInteraction: true
    }}
    pagination={{
      clickable: true
    }}
    modules={[Pagination, Autoplay, Navigation]}
    className="carousel-section">
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
    <SwiperSlide>
      <Image src={CarouselImage} />
    </SwiperSlide>
  </Swiper>
);

export default Carousel;
