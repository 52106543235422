import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Divider, Typography } from '@mui/material';
import { styled } from 'styled-components';

import MainPic from '../../assets/images/main_pic.jpg';
import Carousel from '../../components/Carousel';
import CategoriesList from '../../components/CategoriesList';
import ProductsList from '../../components/ProductsList';
import Signatures from '../../components/Signatures';
import ButtonLink from '../../shared/components/ButtonLink';
import SearchBar from '../../shared/components/SearchBar';

const Container = styled.div`
  flex: 3;
  overflow-y: scroll;
  min-height: 100vh;
  margin: 0;
  position: relative;
  background-color: white;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const LandingPageContent = () => (
  <Container>
    <Image src={MainPic} />
    <Carousel />

    <Signatures />
    <div style={{ height: '800px', backgroundColor: '#a7b5ed' }}></div>
    <Box ml={2} mt={3} mb={2} mr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Typography fontWeight={600} variant="h6">
          SPOTLIGHT
        </Typography>
        <Typography variant="body2" mt={0.5}>
          ĐANG SẮP XẾP THEO: NGẪU NHIÊN
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          gap: '10px'
        }}>
        <ButtonLink sx={{ flex: 1 }} content="XEM THÊM" link="./products" />
        <ArrowForwardIcon fontSize="small" />
      </Box>
    </Box>

    <ProductsList />
    <Divider style={{ backgroundColor: 'grey' }} />
    <CategoriesList />
    <Divider style={{ backgroundColor: 'grey' }} />
    <SearchBar />
  </Container>
);

export default LandingPageContent;
