import { createRef, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import styled from 'styled-components';

import CarouselImage from '../../assets/images/product.jpg';

const ImagePreview = styled.img`
  width: 100%;
  cursor: pointer;
  opacity: 0.5;
  &.active {
    opacity: 1;
    border: 1px solid black;
    width: 48px;
  }
`;

const ImageShowItem = styled.img`
  object-fit: cover;
  height: 100%;
`;

const ImagePreviewScroll = () => {
  const theme = useTheme();
  const items = [
    { id: 'random-1', src: CarouselImage },
    { id: 'random-2', src: CarouselImage },
    { id: 'random-3', src: CarouselImage },
    { id: 'random-4', src: CarouselImage },
    { id: 'random-5', src: CarouselImage },
    { id: 'random-6', src: CarouselImage },
    { id: 'random-7', src: CarouselImage },
    { id: 'random-8', src: CarouselImage },
    { id: 'random-9', src: CarouselImage },
    { id: 'random-10', src: CarouselImage }
  ];

  const [selectedImageId, setSelectedImageId] = useState<string>(items[0].id);

  const containerRef = createRef<HTMLDivElement>();
  const imgRefs = Array.from({ length: items.length }, () => createRef<HTMLImageElement>());

  const handleImagePreviewClick = (itemId: string, indexItem: number) => {
    if (itemId !== selectedImageId) {
      setSelectedImageId(itemId);

      if (containerRef.current !== null && imgRefs[indexItem] !== null) {
        const selectedTagRef = imgRefs[indexItem].current;

        if (selectedTagRef !== null) {
          containerRef.current.scrollTo({
            top: selectedTagRef.offsetTop,
            behavior: 'smooth'
          });
        }
      }
    }
  };

  return (
    <Box display={'flex'} height={'100%'}>
      <Stack
        component={'div'}
        overflow={'hidden'}
        height={'100%'}
        ref={containerRef}
        width={'100%'}
        alignItems={'center'}>
        {items.map((item, index) => (
          <ImageShowItem
            ref={imgRefs[index]}
            id={item.id}
            key={item.id}
            src={item.src}
            alt="imggggg"
          />
        ))}
      </Stack>
      <Box borderLeft={`1px solid ${theme.palette.divider}`} width={'50px'}>
        {items.map((item, index) => (
          <ImagePreview
            key={item.id}
            src={item.src}
            alt="imggggg"
            onClick={() => {
              handleImagePreviewClick(item.id, index);
            }}
            className={selectedImageId === item.id ? 'active' : ''}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImagePreviewScroll;
