import Signature4 from '../../assets/images/sig_1.jpg';

export const PRODUCTS_LIST_DATA = [
  {
    id: '1',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '2',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '3',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '4',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '5',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '6',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '7',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  },
  {
    id: '8',
    image: Signature4,
    price: 150000,
    name: 'SƠMI HAWAIIAN V878945'
  }
];
