import {
  Box,
  Divider,
  Typography
  //  useMediaQuery
} from '@mui/material';

// import required modules
// import { Navigation } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import CarouselImage from '../../assets/images/product.jpg';
import ImagePreviewScroll from '../../components/ImagePreviewScroll';
import SearchBar from '../../shared/components/SearchBar';
import NavBar from '../ProductsPageContent/NavBar';

// Import Swiper styles
import 'swiper/css';

const ProductDetailPage = () => {
  // const matches = useMediaQuery('(min-width:1400px)');

  return (
    <Box sx={{ flex: 3, height: '95vh' }}>
      <NavBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <Divider />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 2, height: '90vh' }}>
            <ImagePreviewScroll />
            {/* <Swiper
              centeredSlides={true}
              navigation={true}
              modules={[Navigation]}
              style={{ width: matches ? '50vw' : '70vw' }}>
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={CarouselImage}
                  style={{ alignItems: 'center', width: '90%', height: 'auto' }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={CarouselImage} style={{ width: '90%', height: 'auto' }} />
              </SwiperSlide>
            </Swiper> */}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1 }}>
            <Box sx={{ flex: 1, p: 5, display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <Box>
                <Typography>CHÀO BẠN,</Typography>
                <Typography>MẪU SƠMI HAWAIIAN V87894,</Typography>
                <Typography>
                  CÓ GIÁ <b>150.000 VNĐ</b>
                </Typography>
              </Box>
              <Box>
                <Typography>SIZE M (ƯỚC LƯỢNG)</Typography>
                <Typography>
                  MÀU <u>XANH LÁ</u>
                </Typography>
                <Typography>
                  VÀ SỐ LƯỢNG LÀ <u>1</u>
                </Typography>
              </Box>
              <Box>
                <Typography>BẠN CÓ THỂ THÊM VÀO GIỎ</Typography>
              </Box>
              <Box>
                <Typography fontWeight={600}>HOẶC XEM THÊM MÔ TẢ SẢN PHẨM</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Thông tin: 1232134543534543 brand
                </Typography>
                <Typography variant="body2">Chất vải: 1232134543534543 brand</Typography>
                <Typography variant="body2">Form: 1232134543534543 brand</Typography>
                <Typography variant="body2">Số đo: 1232134543534543 brand</Typography>
                <Typography variant="body2">Ngang: 56 Dài: 76</Typography>
              </Box>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography>HƯỚNG DẪN CHỌN SIZE</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography>GIAO HÀNG VÀ THANH TOÁN</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <SearchBar />
      </Box>
    </Box>
  );
};

export default ProductDetailPage;
