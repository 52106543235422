import { Box, Button, Grid, Typography } from '@mui/material';
import styled from 'styled-components';

import CartItemEditable from '../../components/CartItemEditable';
import { itemsCartSelector, totalItemQtySelector } from '../../redux/features/CartSlice';
import { useAppSelector } from '../../redux/store';
import SearchBar from '../../shared/components/SearchBar';

const Container = styled.div`
  flex: 3;
  overflow-y: scroll;
  min-height: 100vh;
  margin: 0;
  position: relative;
  background-color: white;
`;

const Divider = styled.hr`
  background-color: grey;
`;

const CartPageContent = () => {
  const carts = useAppSelector(itemsCartSelector);
  const totalItems = useAppSelector(totalItemQtySelector);

  return (
    <Container>
      <Box
        position={'sticky'}
        top={0}
        left={0}
        bgcolor={'white'}
        zIndex={1}
        display={'flex'}
        justifyContent={'space-between'}
        borderBottom={'1px solid grey'}
        alignItems={'center'}>
        <Typography variant="h4">GIỎ HÀNG ({totalItems})</Typography>
        <Box display={'flex'} alignItems={'center'}>
          <Box px={2}>
            <Typography fontFamily="monospace" fontSize={16} ml={1} mr={1}>
              Tổng 840000 VND
            </Typography>
            <Typography variant="caption">* BAO GỒM THUẾ VAT</Typography>
          </Box>
          <Button
            sx={{
              height: '60px',
              color: 'white',
              background: 'black',
              borderRadius: 0,
              paddingX: 2.5,
              ':hover': {
                color: 'white',
                background: '#2f2f2f'
              }
            }}>
            Thanh Toán
          </Button>
        </Box>
      </Box>
      <Grid container rowGap={0.5}>
        {carts.length > 0 ? (
          carts.map(({ product, qty }, index) => (
            <Grid item key={`cart-item-editable-${index}`} xs={6} md={4} lg={3}>
              <CartItemEditable product={product} qty={qty} />
            </Grid>
          ))
        ) : (
          <Typography variant="body2">Chưa có sản phẩm nào trong giỏ hàng.</Typography>
        )}
      </Grid>

      <Divider />
      <SearchBar />
    </Container>
  );
};

export default CartPageContent;
