import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['Source Code Pro'].join(',')
  }
});

const Theme = ({ children }: { children: React.ReactElement }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
