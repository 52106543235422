import React from 'react';

import EditorialsPageContent from '../../layouts/EditorialsPageContent';
import PageLayout from '../../layouts/PageLayout';

const EditorialsPage = () => (
  <PageLayout>
    <EditorialsPageContent />
  </PageLayout>
);

export default EditorialsPage;
