import React, { Fragment } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Divider, Stack, Typography } from '@mui/material';

const CATEGORIES = [
  {
    parent: 'ÁO',
    children: [
      [{ name: 'SƠMI HAWAIIAN' }, { name: 'SƠMI DENIM' }, { name: 'SƠMI PLANNEL' }],
      [{ name: 'SƠMI NHUNG' }, { name: 'SƠMI LINEN' }, { name: 'SƠMI TRẮNG' }],
      [{ name: 'SƠMI KHAKI' }],
      [{ name: 'SWEATER' }, { name: 'HOODIE' }],
      [{ name: 'ÁO BÓNG CHÀY' }],
      [{ name: 'ÁO LEN' }]
    ]
  },
  {
    parent: 'ÁO KHOÁC NGOÀI',
    children: [
      [{ name: 'KHOÁC JEANS' }],
      [{ name: 'KHOÁC DÙ' }, { name: 'KHOÁC BOMBER' }],
      [{ name: 'KHOÁC NHỈ' }, { name: 'KHOÁC KHAKI' }],
      [{ name: 'WORKWEAR' }, { name: 'VARSITY' }],
      [{ name: 'GILET' }],
      [{ name: '' }]
    ]
  },
  {
    parent: 'QUẦN',
    children: [
      [{ name: 'QUẦN JEANS' }],
      [{ name: 'QUẦN KHAKI' }, { name: 'QUẦN JOGGER' }, { name: 'QUẦN TÚI HỘP' }],
      [{ name: 'QUẦN WORKWEAR' }, { name: 'QUẦN NHUNG' }, { name: 'QUẦN NỈ/QUẦN TÂY' }],
      [{ name: 'QUẦN LINEN' }],
      [{ name: 'QUẦN SHORT' }],
      [{ name: '' }]
    ]
  },
  {
    parent: 'BLAZER',
    children: [
      [{ name: 'BLAZER' }],
      [{ name: 'MĂNG TÔ' }],
      [{ name: 'GILET VEST' }, { name: 'GILET DA' }],
      [{ name: '' }],
      [{ name: '' }],
      [{ name: '' }]
    ]
  },
  {
    parent: 'PHỤ KIỆN',
    children: [
      [{ name: 'VỚ' }, { name: 'TÚI' }, { name: 'NÓN' }, { name: 'KÍNH' }],
      [{ name: 'VÒNG CỔ' }, { name: 'VÒNG TAY' }, { name: 'NHẪN' }],
      [{ name: 'THẮT LƯNG' }, { name: 'DÂY CHỮ Y' }],
      [{ name: 'CRAVAT' }],
      [{ name: '' }],
      [{ name: '' }]
    ]
  }
];

const CategoriesList = () => (
  <Box pb={3}>
    {CATEGORIES.map(({ parent, children }, categoryIndex) => (
      <Fragment key={`category-item-${categoryIndex}`}>
        <Box
          pt={2}
          pb={2}
          sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between'
          }}>
          <Box pl={3} pr={3} sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={10} fontWeight={600}>
              {parent}
            </Typography>
            <ArrowForwardIcon fontSize="small" />
          </Box>

          {children.map((child, index) => (
            <Stack key={`category-children-item-${index}`} spacing={0.5} sx={{ flex: 1 }}>
              {child.map(({ name }, indexChild) => (
                <Typography fontSize={10} key={`category-name-${indexChild}`}>
                  {name}
                </Typography>
              ))}
            </Stack>
          ))}
        </Box>
        <Divider style={{ backgroundColor: 'grey' }} />
      </Fragment>
    ))}
  </Box>
);

export default CategoriesList;
