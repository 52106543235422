import React from 'react';
import { Box, createTheme } from '@mui/material';

import ButtonLink from '../shared/components/ButtonLink';

const SOCIAL_ITEMS = [
  {
    link: 'https://www.facebook.com/vac.closet',
    content: 'FACEBOOK'
  },
  {
    link: 'https://www.instagram.com/vac.closet',
    content: 'INSTAGRAM'
  },
  {
    link: 'https://www.facebook.com/vac.closet',
    content: 'THREAD'
  }
];

const SocialsBar = () => {
  const theme = createTheme();

  return (
    <Box ml={3}>
      <Box sx={{ display: 'flex', gap: theme.spacing(3), p: theme.spacing(0.2), mt: 0.3, mb: 0.3 }}>
        {SOCIAL_ITEMS.map(({ link, content }) => (
          <ButtonLink
            key={content}
            link="https://www.facebook.com/vac.closet"
            content="FACEBOOK"
            props={{ fontSize: 10, fontWeight: 600 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SocialsBar;
