import React from 'react';

import PageLayout from '../../layouts/PageLayout';
import ProductDetailPageContent from '../../layouts/ProductDetailPageContent';

const ProductDetailPage = () => (
  <PageLayout>
    <ProductDetailPageContent />
  </PageLayout>
);

export default ProductDetailPage;
