import { Box, Divider, Link, Typography } from '@mui/material';

import ProductItem from '../../components/ProductItem';
import SearchBar from '../../shared/components/SearchBar';
import { PRODUCTS_LIST_DATA } from '../../shared/mock-data';

import NavBar from './NavBar';

const ProductsPageContent = () => {
  return (
    <Box sx={{ flex: 3, overflowY: 'scroll' }}>
      <NavBar />
      <Divider />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '36px' }}>
        {PRODUCTS_LIST_DATA.map(({ image, price, name, id }) => (
          <Box sx={{ flex: '1 0 21%' }} key={`product-item-${id}`}>
            {name === 'none' ? (
              <Box
                px={4}
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: '10px',
                  borderRight: '1px solid grey'
                }}>
                <Typography variant="h4">SƠMI HAWAIIAN</Typography>
                <Link color="inherit">▲ CLICK VÀO ĐÂY ĐỂ HIỆN THỊ NHANH MÔ TẢ SẢN PHẨM</Link>
              </Box>
            ) : (
              <ProductItem image={image} price={price} name={name} id={id} />
            )}
          </Box>
        ))}
      </Box>
      <Divider />
      <SearchBar />
    </Box>
  );
};

export default ProductsPageContent;
