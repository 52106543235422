import React from 'react';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';

import Articles from '../../components/Articles';
import ContactInfo from '../../components/ContactInfo';
import SocialsBar from '../../components/NavBar';
import ButtonLink from '../../shared/components/ButtonLink';

const MainRightSection = () => {
  const matches = useMediaQuery('(min-width:1400px)');

  return (
    <Box
      sx={{
        display: matches ? 'flex' : 'none',
        flex: 1,
        flexDirection: 'column',
        borderLeft: '1px solid grey'
      }}>
      <SocialsBar />
      <Box sx={{ borderBottom: '1px solid grey' }} />
      <Box sx={{ width: '200px' }} ml={3}>
        <Link href="/" underline="hover" color="inherit">
          <Typography variant="h4" fontWeight={500} sx={{ margin: '15px 0px 5px 0px' }}>
            VẠC&apos;s CLOSET
          </Typography>
        </Link>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ButtonLink link="/products" content="SẢN PHẨM" sx={{ fontWeight: 600 }} />
          <ButtonLink link="/discovery" content="KHÁM PHÁ" sx={{ fontWeight: 600 }} />
          <ButtonLink link="/editorials" content="EDITORIAL" sx={{ fontWeight: 600 }} />
        </Box>
      </Box>
      <Articles />
      <ContactInfo />
    </Box>
  );
};

export default MainRightSection;
