import { useDispatch } from 'react-redux';
import { Box, Button, Drawer, Stack, Typography } from '@mui/material';

import CartMiniItem from '../../components/CartMiniItem';
import { itemsCartSelector, stateDrawerCart, toggleCart } from '../../redux/features/CartSlice';
import { useAppSelector } from '../../redux/store';

const CartDrawer = () => {
  const carts = useAppSelector(itemsCartSelector);
  const state = useAppSelector(stateDrawerCart);
  const dispatch = useDispatch();

  return (
    <Drawer
      anchor={'right'}
      open={state}
      onClose={() => {
        dispatch(toggleCart(false));
      }}>
      <Stack width={430} p={3} pb={0} gap={0.5}>
        <Box pb={1}>
          <Typography variant="h5" textAlign={'right'}>
            GIỎ HÀNG
          </Typography>
        </Box>

        {carts.map((cart, index) => (
          <CartMiniItem key={`cart-mini-item-${index}`} {...cart} />
        ))}

        <Box position={'sticky'} bottom={0} left={0} sx={{ backgroundColor: 'white' }}>
          <Button
            variant="text"
            color="inherit"
            sx={{
              mt: 1,
              border: `1px solid #333333`,
              width: '100%'
            }}
            onClick={() => {
              window.open('/cart', '_blank');
            }}>
            XEM GIỎ HÀNG
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default CartDrawer;
