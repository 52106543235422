import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from '../store';

export interface EditorialItem {
  id?: number | string;
  img: string;
  title: string;
  description: string;
  cols?: number;
  rows?: number;
}

interface EditorialState {
  items: EditorialItem[];
}

const initialState: EditorialState = {
  items: []
};

export const EditorialSlice = createSlice({
  name: 'editorial',
  initialState,
  reducers: {
    openEditorialModal: (state, action: PayloadAction<EditorialItem>) => {
      state.items.push(action.payload);
    },
    closeEditorialModal: (state) => {
      state.items = [];
    }
  }
});

export default EditorialSlice.reducer;

const items = (state: RootState) => state.editorial.items;
export const totalSelectedEditorials = createSelector([items], (items) => items.length);

export const { openEditorialModal, closeEditorialModal } = EditorialSlice.actions;
