import PageLayout from '../../layouts/PageLayout';
import ProductsPageContent from '../../layouts/ProductsPageContent';

const ProductsPage = () => (
  <PageLayout>
    <ProductsPageContent />
  </PageLayout>
);

export default ProductsPage;
