import React from 'react';
import { Box, Breadcrumbs, Divider, Link, Typography } from '@mui/material';

import Filters from './Filters';

const NavBar = () => (
  <Box sx={{ display: 'flex', flex: 0 }}>
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          SẢN PHẨM
        </Link>
        <Link underline="hover" color="inherit" href="">
          ÁO
        </Link>
        <Typography color="text.primary">SƠMI HAWAIIAN</Typography>
      </Breadcrumbs>
    </Box>
    <Divider orientation="vertical" flexItem />

    <Filters />
  </Box>
);

export default NavBar;
