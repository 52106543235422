import { BrowserRouter } from 'react-router-dom';

import RoutesChild from '../routes';
import Theme from '../Theme';

const App = () => (
  <Theme>
    <BrowserRouter>
      <RoutesChild />
    </BrowserRouter>
  </Theme>
);

export default App;
