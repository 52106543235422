import { useDispatch } from 'react-redux';
import { Box, Divider, ImageList, Typography, useMediaQuery } from '@mui/material';

import Article4 from '../../assets/images/article_1.jpg';
import Article2 from '../../assets/images/article_2.png';
import Article3 from '../../assets/images/article_3.png';
import MainPic from '../../assets/images/main_pic.jpg';
import ArticleItem from '../../components/ArticleItem';
import { openEditorialModal } from '../../redux/features/EditorialSlice';
import SearchBar from '../../shared/components/SearchBar';

const itemData = [
  {
    img: MainPic,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article2,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article3,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article4,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do.'
  }
];

const DiscoveryPageContent = () => {
  const matches = useMediaQuery('(min-width:1400px)');
  const dispatch = useDispatch();

  return (
    <Box sx={{ flex: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignText: 'center'
        }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignText: 'center'
          }}>
          <Typography fontWeight={600} fontSize={12} sx={{ mt: 0.3, mb: 0.2 }}>
            MIX-MATCH
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem style={{ background: 'grey' }} />
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignText: 'center'
          }}>
          <Typography fontWeight={600} fontSize={12} sx={{ mt: 0.3, mb: 0.2 }}>
            DAILY-UPDATE
          </Typography>
        </Box>
      </Box>
      <Divider style={{ background: 'grey' }} />

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ flex: 1, overflow: 'scroll', height: '95vh' }}>
          <ImageList cols={1} gap={0} sx={{ padding: 0, margin: 0 }}>
            {itemData.map(({ img, title, description }, index) => (
              <ArticleItem key={index} img={img} title={title} description={description} />
            ))}
          </ImageList>
        </Box>

        <Divider orientation="vertical" flexItem style={{ background: 'grey' }} />

        <Box sx={{ flex: 2, overflow: 'scroll', height: '95vh' }}>
          <ImageList
            cols={matches ? 2 : 1}
            gap={0}
            variant="masonry"
            sx={{ padding: 0, margin: 0 }}>
            {itemData.map((item, index) => (
              <div
                key={index}
                onClick={() => dispatch(openEditorialModal(item))}
                style={{ cursor: 'pointer' }}>
                <ArticleItem img={item.img} title={item.title} description={item.description} />
              </div>
            ))}
          </ImageList>
        </Box>
      </Box>

      <Divider />
      <SearchBar />
    </Box>
  );
};

export default DiscoveryPageContent;
