import { Box, ImageListItem } from '@mui/material';

import ImageTextItem from '../ImageTextItem';

interface ArticleItemProps {
  img: string;
  title: string;
  description: string;
}

const ArticleItem = ({ img, title, description }: ArticleItemProps) => (
  <ImageListItem key={img}>
    <img src={img} alt={title} loading="lazy" />
    <Box p={1}>
      <ImageTextItem props={{ fontWeight: 600 }} content={title} />
      <ImageTextItem numberOfLines={2} props={{ variant: 'caption' }} content={description} />
    </Box>
  </ImageListItem>
);

export default ArticleItem;
