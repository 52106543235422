import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { LocalStorageKey } from '../../constants';
import { parseArrayJsonLocalStorage } from '../../shared/helpers/common';

import { type RootState } from './../store';

export interface Product {
  id: string;
  name: string;
  price: number;
  image: string;
}
export interface CartItem {
  product: Product;
  qty: number;
}

interface CartState {
  items: CartItem[];
  showCart: boolean;
}

const initialState: CartState = {
  items: parseArrayJsonLocalStorage<CartItem>(LocalStorageKey.CART),
  showCart: false
};

export const CartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleCart: (state, action: PayloadAction<boolean>) => {
      state.showCart = action.payload;
    },
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const addCartItemIndex = state.items.findIndex(
        (item) => item.product.id === action.payload.product.id
      );

      if (addCartItemIndex !== -1) {
        const updatedItem = state.items[addCartItemIndex];

        state.items.splice(addCartItemIndex, 1, {
          ...updatedItem,
          qty: updatedItem.qty + 1
        });
      } else {
        state.items.push(action.payload);
      }
      state.showCart = true;
      localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
    },
    updateQuantityCart: (
      state,
      action: PayloadAction<{ type: 'increase' | 'decrease'; id: Product['id'] }>
    ) => {
      const updateCartItemIndex = state.items.findIndex(
        (item) => item.product.id === action.payload.id
      );

      if (updateCartItemIndex !== -1) {
        const updatedItem = state.items[updateCartItemIndex];

        const updatedQuantity: { [K in typeof action.payload.type]: number } = {
          decrease: updatedItem.qty - 1,
          increase: updatedItem.qty + 1
        };

        state.items.splice(updateCartItemIndex, 1, {
          ...updatedItem,
          qty: updatedQuantity[action.payload.type]
        });

        localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
      }
    },
    deleteItemCart: (state, action: PayloadAction<{ id: Product['id'] }>) => {
      const deleteCartItemIndex = state.items.findIndex(
        (item) => item.product.id === action.payload.id
      );

      if (deleteCartItemIndex !== -1) {
        state.items.splice(deleteCartItemIndex, 1);
        localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
      }
    }
  }
});

export default CartSlice.reducer;
export const { addToCart, toggleCart, updateQuantityCart, deleteItemCart } = CartSlice.actions;

export const stateDrawerCart = createSelector(
  [(state: RootState) => state.cart.showCart],
  (showCart) => showCart
);

const items = (state: RootState) => state.cart.items;

export const itemsCartSelector = createSelector([items], (items) => items);

export const totalItemQtySelector = createSelector([items], (items) => {
  return items.reduce((total: number, curr: CartItem) => (total += curr.qty), 0);
});

export const totalQtyLimitSelector = createSelector(
  [items, (items, limit: number) => limit],
  (items, limit) => {
    const total = items.reduce((total: number, curr: CartItem) => (total += curr.qty), 0);
    return total > limit;
  }
);
