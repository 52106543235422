import { Link, type LinkProps, type SxProps, type Theme } from '@mui/material';

interface ButtonLinkProps {
  link: string;
  content: string;
  props?: LinkProps;
  sx?: SxProps<Theme>;
}

const ButtonLink = ({ link, content, props, sx }: ButtonLinkProps) => (
  <Link
    href={link}
    target="_blank"
    rel="noreferrer"
    underline="none"
    color="black"
    {...{
      variant: 'body2',
      ...props
    }}
    sx={sx}>
    {content}
  </Link>
);

export default ButtonLink;
