import { Box } from '@mui/material';

import { PRODUCTS_LIST_DATA } from '../../shared/mock-data';
import ProductItem from '../ProductItem';

const ProductsList = () => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {PRODUCTS_LIST_DATA.map(({ image, price, name, id }) => (
      <Box sx={{ flex: '1 0 21%' }} key={name}>
        <ProductItem image={image} price={price} name={name} id={id} />
      </Box>
    ))}
  </Box>
);

export default ProductsList;
