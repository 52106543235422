import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, ImageList, ImageListItem, Typography } from '@mui/material';
import { keyframes, styled } from 'styled-components';

import Article2 from '../../assets/images/article_2.png';
import Article3 from '../../assets/images/article_3.png';
import MainPic from '../../assets/images/main_pic.jpg';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const slideIn = keyframes`
  0% {
    transform: translateY(80vh);
  }
  50% {
    transform: translateY(-20vh);
  }
  100% {
    transform: translateY(0vh);
  }
`;

const SlideUpContainer = styled.div`
  animation: ${slideIn} 1s ease-in-out;
  height: 80vh;
  display: flex;
  border-top: 1px solid grey;
  background-color: white;
`;

const srcset = (image: string, size: number, rows = 1, cols = 1) => ({
  src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
  srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`
});

const itemData: Array<{ img: string; rows?: number; cols?: number }> = [
  {
    img: Article3
  },
  {
    img: MainPic
  },
  {
    img: Article2,
    rows: 2,
    cols: 2
  },
  {
    img: MainPic
  },
  {
    img: Article2
  }
];

const EditorialDetailModal = ({ closeModal }: { closeModal: () => void }) => (
  <Container>
    <SlideUpContainer>
      <Box
        style={{
          flex: 4,
          display: 'flex',
          flexDirection: 'column',
          animation: '10s slide-up'
        }}>
        <Box sx={{ width: '30%' }} p={2}>
          <Typography fontWeight={600} style={{ fontSize: '12px' }}>
            MIGHGTY
          </Typography>
          <Typography style={{ fontSize: '10px' }} variant="caption">
            Làn gió sau lưng, vó ngựa rền vàng
          </Typography>
          <br style={{ margin: 0, padding: 0 }} />
          <Typography variant="caption" style={{ fontSize: '10px' }}>
            Chăng bản giờ biết được cáo gì ở chân trời.
          </Typography>
          <br style={{ margin: 0, padding: 0 }} />
          <Typography variant="caption" style={{ fontSize: '10px' }}>
            Tất cả đều là Cảm giác tự do
          </Typography>
        </Box>
        <Divider style={{ backgroundColor: 'grey' }} />

        <Box
          m={0}
          p={0}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll'
          }}>
          <ImageList variant="quilted" cols={2} gap={0} style={{ margin: 0, padding: 0 }}>
            {itemData.map((item, index) => (
              <ImageListItem
                key={index}
                cols={item.cols ?? 1}
                rows={item.rows ?? 1}
                style={{ margin: 0, padding: 0 }}>
                <img {...srcset(item.img, 121, item.rows, item.cols)} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Box>
      <Divider orientation="vertical" sx={{ backgroundColor: 'grey' }} />
      <Box style={{ flex: 1 }} p={2}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon onClick={closeModal} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box
          pt={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <Typography variant="caption">THE FIELDS</Typography>
          <Typography variant="caption">IN THE NIGHT</Typography>
          <Typography variant="caption">THE DEPTH</Typography>
          <Typography variant="caption">STRANGER IN THE CITY</Typography>
          <Typography variant="caption">IN THE NIGHT</Typography>
        </Box>
      </Box>
    </SlideUpContainer>
  </Container>
);

export default EditorialDetailModal;
