import React from 'react';
import { Box } from '@mui/material';

import Article1 from '../../assets/images/article_1.jpg';
import Article2 from '../../assets/images/article_2.png';
import Article3 from '../../assets/images/article_3.png';
import ArticleItem from '../ArticleItem';

const articleList = [
  {
    img: Article1,
    title: 'Chapter 1: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article2,
    title: 'Chapter 2: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  },
  {
    img: Article3,
    title: 'Chapter 3: Bắt trọn vẻ đẹp phóng khoáng vượt thời gian cùng áo khoác Jean nhà Vạc',
    description:
      'Làn gió sau lưng, vó ngựa rền vang, Chẳng bao giờ biết được có gì ở chân trời Tất cả đều là cảm giác tự do'
  }
];

const Articles = () => (
  <Box mt={2} sx={{ overflowY: 'scroll' }}>
    {articleList.map(({ img, title, description }, index) => (
      <ArticleItem key={index} img={img} title={title} description={description} />
    ))}
  </Box>
);

export default Articles;
