import { type ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { closeEditorialModal, totalSelectedEditorials } from '../redux/features/EditorialSlice';
import { useAppSelector } from '../redux/store';
import { MainContainer } from '../shared/styles';

import CartDrawer from './CartDrawer';
import EditorialDetailModal from './EditorialDetailModal';
import RightSection from './MainRightSection';
const PageLayout = ({ children }: { children: ReactElement }) => {
  const dispatch = useDispatch();
  const isOpenEditorialDetail = useAppSelector(totalSelectedEditorials) > 0;

  return (
    <Box sx={{ position: 'relative' }}>
      <MainContainer style={{ opacity: isOpenEditorialDetail ? 0.3 : 1 }}>
        {children}
        <RightSection />
      </MainContainer>

      {isOpenEditorialDetail && (
        <EditorialDetailModal closeModal={() => dispatch(closeEditorialModal())} />
      )}

      <CartDrawer />
    </Box>
  );
};

export default PageLayout;
